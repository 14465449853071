import React from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import HeroesLinksList from "@/game-deadlock/components/HeroesLinksList.jsx";
import HeroPageHeader from "@/game-deadlock/components/HeroPageHeader.jsx";
import ItemImage from "@/game-deadlock/components/ItemImage.jsx";
import {
  ITEM_TIER_COSTS,
  ITEM_TIER_VAL,
} from "@/game-deadlock/constants/items.mjs";
import { TableContainer } from "@/game-deadlock/HeroItemsStarting.style.jsx";
import { Content } from "@/game-deadlock/HeroOverview.style.jsx";
import { getItemColor } from "@/game-deadlock/utils/get-item-color.mjs";
import { getHeroIdFromName } from "@/game-deadlock/utils/getHeroIdFromName.mjs";
import { localeString } from "@/game-deadlock/utils/localestring.mjs";
import { useHeroStats } from "@/game-deadlock/utils/use-hero-stats.mjs";
import { useHeroesList } from "@/game-deadlock/utils/use-heroes-list.mjs";
import { useItemsAbilities } from "@/game-deadlock/utils/use-items-abilties.mjs";
import DataTable, { DataTableLoading } from "@/shared/DataTable.jsx";
import PageContainer from "@/shared/PageContainer.jsx";
import { useRoute } from "@/util/router-hooks.mjs";

function HeroStartingItems() {
  const { t } = useTranslation();
  const {
    parameters: [heroIdOrName],
  } = useRoute();

  const {
    loading: _itemsLoading,
    dict: itemsStaticData,
    names: itemsStaticNames,
  } = useItemsAbilities();
  const { loading: _heroesListLoading, list: _heroesList } = useHeroesList();
  const {
    loading: heroStatsLoading,
    stats: heroStats,
    heroId,
    heroName,
  } = useHeroStats(heroIdOrName);

  return (
    <PageContainer>
      <HeroPageHeader
        heroId={heroId}
        heroName={heroName}
        stats={heroStats}
        titleSuffix={t("", "Early game starting items stats")}
      />
      <section className={Content()}>
        <div className="main-col">
          {heroStatsLoading ? (
            <DataTableLoading tallRows loadingRows={14} />
          ) : (
            <DataTable
              tallRows
              sortCol={1} // win rate
              className={TableContainer()}
              cols={[
                {
                  align: "left",
                  display: t("deadlock:stats.earlyItemsSet", "Early Items Set"),
                  className: "items-meta",
                },
                {
                  display: t("common:stats.winRate", "Win Rate"),
                  className: "winrate",
                  isStat: true,
                },
                {
                  display: t("common:stats.pickRate", "Pick Rate"),
                  className: "pickrate",
                  isStat: true,
                },
                {
                  display: t("common:stats.cost", "Cost"),
                  className: "cost",
                  isStat: true,
                },
              ]}
              rows={heroStats.first_3_items_stats.map((itemSet) => {
                const itemSetCost = itemSet.items.reduce((acc, itemId) => {
                  const itemCost =
                    ITEM_TIER_COSTS[itemsStaticData[itemId]?.m_iItemTier] || 0;
                  return acc + itemCost;
                }, 0);
                return [
                  {
                    display: (
                      <div className="items-meta">
                        {itemSet.items
                          .sort((a, b) => {
                            const aTierVal =
                              ITEM_TIER_VAL[itemsStaticData[a]?.m_iItemTier] ||
                              0;
                            const bTierVal =
                              ITEM_TIER_VAL[itemsStaticData[b]?.m_iItemTier] ||
                              0;
                            return aTierVal - bTierVal;
                          })
                          .map((itemId) => {
                            const itemStaticData = itemsStaticData[itemId];
                            return (
                              <div
                                key={itemId}
                                className="item-meta"
                                style={{
                                  "--wep-category-color":
                                    getItemColor(itemStaticData),
                                }}
                              >
                                <ItemImage
                                  itemId={itemId}
                                  size={28}
                                  withRoman
                                  className="item-img"
                                />
                                <span className="item-name type-caption--bold">
                                  {itemsStaticNames[itemId] || ""}
                                </span>
                              </div>
                            );
                          })}
                      </div>
                    ),
                    value: "",
                  },
                  {
                    display: localeString(itemSet.win_rate, true, 1),
                    value: itemSet.win_rate,
                  },
                  {
                    display: localeString(itemSet.pick_rate, true, 1),
                    value: itemSet.pick_rate,
                  },
                  {
                    display: localeString(itemSetCost, false, 0),
                    value: itemSetCost,
                  },
                ];
              })}
            />
          )}
        </div>
        <aside className="side-col">
          <HeroesLinksList heroIdOrName={heroIdOrName} tab="starting-items" />
        </aside>
      </section>
    </PageContainer>
  );
}

export default HeroStartingItems;

export function meta([heroIdOrName]: [string]) {
  const heroId = getHeroIdFromName(heroIdOrName, readState.deadlock.heroes);
  const hero = readState.deadlock.heroes[heroId];
  const name = hero?.heroName;
  return {
    title: name
      ? [
          "deadlock:meta.heroPage.title",
          "{{name}}'s Deadlock Win Rate, Pick Rate, and tier list – Blitz Deadlock",
          { name },
        ]
      : [
          "deadlock:meta.tierlist.title",
          "Deadlock Tier list, Win Rates, Pick Rates, and more – Blitz Deadlock",
        ],
    description: [],
    subtitle: name
      ? [
          "deadlock:meta.heroNameSubtitle",
          "Deadlock {{name}} Abilities Stats, Item builds, Matchups, Counters, Synergies, and more for Valve's new Shooter MOBA.",
          { name },
        ]
      : [
          "deadlock:meta.heroSubtitle",
          "Deadlock Hero Abilities Stats, Item builds, Matchups, Counters, Synergies, and more for Valve's new Shooter MOBA.",
        ],
  };
}
